import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
* @typedef {Object} SimulatorData
* @property {String} creditApplicationId
* @property {String} productId
* @property {Number} requestedAmount
* @property {Number} terms
* @property {String} period
* @property {String} maritalStatusId
* @property {Number} income
* @property {Number} expenses
* @property {Number} dependents
* @property {Number} homeSinceTotalMonths
*/

/**
 * @class
 * @param  {String} creditApplicationId
 */
export class OfertaSimulatorService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._productId = ''
    this._requestedAmount = 0
    this._terms = 0
    this._period = ''
    this._maritalStatusId = ''
    this._income = 0
    this._expenses = 0
    this._dependents = 0
    this._homeSinceTotalMonths = 0
  }

  /**
   * @param  {String} maritalStatusId
   */
  setMaritalStatusId(maritalStatusId) {
    this._maritalStatusId = maritalStatusId
  }

  /**
   * @param  {Number} income
   */
  setIncome(income) {
    this._income = income
  }

  /**
 * @param  {Number} expenses
 */
  setExpenses(expenses) {
    this._expenses = expenses
  }

  /**
* @param  {Number} dependents
*/
  setDependents(dependents) {
    this._dependents = dependents
  }

  /**
* @param  {Number} homeSinceTotalMonths
*/
  setHomeSinceTotalMonths(homeSinceTotalMonths) {
    this._homeSinceTotalMonths = homeSinceTotalMonths
  }

  /**
   * @param  {String} productId
   */
  setProductId(productId) {
    this._productId = `${productId}`
  }

  /**
   * @param  {Number} requestedAmount
   */
  setRequestedAmount(requestedAmount) {
    this._requestedAmount = requestedAmount + 0
  }

  /**
   * @param  {Number} terms
   */
  setTerms(terms) {
    this._terms = terms + 0
  }

  /**
   * @param  {String} period - Weekly | Biweekly | Monthly
   */
  setPeriod(period) {
    if (['Weekly', 'Biweekly', 'Monthly', 'Semimonthly'].includes(period)) {
      this._period = `${period}`
    } else {
      this._setError({
        type: 'code_exception',
        message: 'period expects "Weekly", "Biweekly", "Monthly", "Semimonthly"'
      })
      throw this.error
    }
  }

  /**
   * @returns {SimulatorData}
   */
  get data() {
    return {
      //   creditApplicationId: `${this._creditApplicationId}`,
      //   productId: `${this._productId}`,
      //   requestedAmount: this._requestedAmount + 0,
      //   terms: this._terms + 0,
      //   period: `${this._period}` 

      creditApplicationId: `${this._creditApplicationId}`,
      maritalStatusId: `${this._maritalStatusId}`,
      income: this._income,
      expenses: this._expenses,
      dependents: this._dependents,
      homeSinceTotalMonths: this._homeSinceTotalMonths
    }
  }

  get reoffer() {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      productId: `${this._productId}`,
      requestedAmount: this._requestedAmount + 0,
      terms: this._terms + 0,
      period: `${this._period}`
    }
  }


  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/score/pension',
      data: self.data
    }))
  }

  sendReoffer() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/offer/reoffer2',
      data: self.reoffer
    }))
  }
} 

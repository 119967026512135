import { css } from '@emotion/react'
import React, { Component, memo } from 'react';
import {
    TitleIcon,
    SliderRange,
} from '@findep/mf-landings-core'
import Alert from '@material-ui/lab/Alert';
import { getPeriodPlural } from '../../helpers/period'
import {
    Divider,
    Snackbar,
    Container,
} from '@material-ui/core';

import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EventIcon from '@material-ui/icons/Event';

import formatNumber from '../../helpers/formatNumber'
import FormaPagoAppOferta from '../elements/FormaPagoAppOferta.compilable'
import withProductNavigate from '../hoc/withProductNavigate'

/**Simulador */
import { OfertaSimulatorService } from '../../services/LandingV4/ofertaSimulador'
import { CataloguesService } from '../../services/LandingV4'
/** */

const divReoundBorder = css`
    border: 2px solid #9BC24C;
    -moz - border - radius: 37px 0px 37px 0px;
    -webkit - border - radius: 37px 0px 37px 0px;
    border-radius: 37px 0px 37px 0px;
    padding: 10px;
    background-color: white;
`

let OfferUpdate = []
const catalogues = new CataloguesService();
class SimuladorCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bandera: false,
            pagototal: this.props.payment,
            dedicacion: '',
            monto: this.props.amount,
            plazo: this.props.paymentPeriod,
            period: this.props.paymentPeriod,
            terms: this.props.paymentTerms,
            pago: this.props.payment,
            flujo: '',
            open: false,
            errorService: false,
            minVar: 3000,
            maxVar: this.props.maximaOferta,
            defaultAmountVar: this.props.amount,//null,
            incrementVar: 1000,
            creditApplicationId: '',
            reoferta: [],
            arrayMeses: [],
            modal: '',
            montoSlider: this.props.amount,
            seguros: this.props.seguros,
            id: this.props.id

        }
        this.handleChange = this.handleChange.bind(this)
    }

    async componentDidMount() {

        let flujo = JSON.parse(sessionStorage.getItem('flujo'))
        this.setState({ creditApplicationId: flujo?.creditApplicationId })
        const valMax = this.props.amount > 120000 ? 120000 : this.props.amount
        const { company } = this.props.pageContext
        this.setState({
            flujo,
            dedicacion: ((company?.toUpperCase() === "FISA" && "FTPE") || (company === "AEF" && '539'))
        })
        sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }

    async componentDidUpdate(prevProps, prevState) {
        const { bandera, id, terms, monto, period, creditApplicationId, montoSlider } = this.state
        if ((bandera && prevState.terms !== terms && terms !== undefined && prevState.terms !== undefined)
            || (bandera && prevState.montoSlider !== montoSlider && prevState.montoSlider !== undefined && montoSlider !== undefined)) {
            const os = new OfertaSimulatorService(creditApplicationId)
            os.setProductId(id)
            os.setRequestedAmount(monto)
            os.setTerms(terms)
            os.setPeriod(period)
            const reofertaPensionados = await os.sendReoffer()
            this.setState({
                pagototal: reofertaPensionados.data.offers[0]?.payment === undefined ? this.props.amount : reofertaPensionados.data.offers[0].payment,
            })
            if (this.props.formal) {
                // this.props.updatepagototal(reofertaPensionados.data.offers[0]?.payment === undefined ? this.props.amount : reofertaPensionados.data.offers[0].payment,)
                this.props.updatepagototal(reofertaPensionados.data.offers)
            }
        }
        if (!bandera) {
            this.setState({
                bandera: true
            })
        }
        if (prevProps.payment !== this.props.payment) {
            this.setState({ pagototal: this.props.payment })
        }
    }

    handleChange(element, value) {
        if (this.state.bandera) {
            if (element === 'monto') {
                this.setState({ montoSlider: value.value })
                this.setState({ monto: value.value })
            }
            if (element === 'terms') {
                this.setState({ terms: value.value })
            }
            if (element === 'period') {
                if (this.props.formal) {
                    this.setState({ period:this.state.period })
                }
                if (value.value === 'Mensual') {
                    this.setState({ period: 'Monthly' })
                }
            }
        }
    }

    render() {
        const { dedicacion, monto, plazo, flujo, period, terms, pago } = this.state
        const { company } = this.props.pageContext
        const isAfi = company?.toUpperCase() === 'AFI'
        const datosFP = {
            formaPago: ` ${terms} ${getPeriodPlural(plazo)}`,
            tituloSelect: "¿En qué plazo lo quieres pagar?",
            colorEmpresa: 'primary'
        }

        const renderSimulador = Boolean(flujo?.ofertaMaximaPensionados)
        return (
            <>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>


                <div css={divReoundBorder}>
                    <TitleIcon disabled={false} icon={AttachMoneyIcon} title="Monto" />
                    <Container>
                        <SliderRange
                            min={this.state.minVar}
                            max={this.state.maxVar}
                            step={this.state.incrementVar}
                            disabled={false}
                            onChange={(event) => { this.handleChange('monto', { value: event.mount }) }}
                            id="simulador-monto"
                            defaultValue={this.state.defaultAmountVar}
                            alternalText={isAfi && `${formatNumber(this.state.monto, 3, ',', '$', 0, '')} dólares`}
                        />
                        <div css={css`display: flex; justify-content: space-between;padding:0 2rem;padding-right:3rem; `}>
                            <div>
                                <div style={{ color: 'primary' }}>
                                    Minímo
                                </div>
                                <div style={{ color: 'primary' }}>
                                    {formatNumber(this.state.minVar, 3, ',', '$', 0, '')}
                                </div>
                            </div>
                            <div>
                                <div style={{ color: 'primary' }}>
                                    Máximo
                                </div>
                                <div style={{ color: 'primary' }}>
                                    {formatNumber(this.state.maxVar, 3, ',', '$', 0, '')}
                                </div>
                            </div>
                        </div>
                    </Container>
                    <br />
                    <Divider />
                    <br />
                    <TitleIcon icon={EventIcon} title={`Plazo: `} />
                    <FormaPagoAppOferta handleChange={this.handleChange} company={company} dedicacion={dedicacion} monto={monto} configuration={datosFP} />
                    <Divider />
                    <TitleIcon icon={MonetizationOnSharpIcon} title={`Pago: ${formatNumber((this.state.pagototal !== null ? this.state.pagototal : 0), 3, ',', '$', 0, '')}`} />
                </div>
            </>
        );
    }
}



export default memo(withProductNavigate(SimuladorCard));
import React, { useEffect } from 'react';
// import { css } from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby"
// import { Box } from '@material-ui/core';
import { FormaPago } from '@findep/mf-landings-core'

const FormaPagoAppOferta = (props) => {
  // const [flujoState, setFlujoState] = useState({})
  const data = useStaticQuery(graphql`
  query {
      allComponentsJson(filter: {component: {eq: "plazos-app"}}) {
        edges {
          node {
            plazos {
              arrayDefault
              company
              producto
              arrayMeses {
                label
              }
              arrayQuincenas {
                label
              }
              arraySemanas {
                label
              }
              labelSemanal
              labelQuincenal
              labelMensual
              colorDefault
            }
          }
        }
      }
    }
`)


  let datosFPApp = props.configuration

  const [formaPago, setFormaPago] = React.useState(undefined)
  const [CF, setCF] = React.useState('')

  // useEffect(() => {

  //   const producto = props.configuration.plazos
  //   datosFPApp.arraySemanas = producto.arraySemanas
  //   datosFPApp.arrayQuincenas = producto.arrayQuincenas
  //   datosFPApp.arrayMeses = producto.arrayMeses
  //   datosFPApp.arrayDefault = producto.arrayDefault[0] === "arraySemanas" && producto.arraySemanas || producto.arrayDefault[0] === "arrayQuincenas" && producto.arrayQuincenas || producto.arrayDefault[0] === "arrayMeses" && producto.arrayMeses
  //   datosFPApp.labelSemanal = producto.labelSemanal
  //   datosFPApp.labelQuincenal = producto.labelQuincenal
  //   datosFPApp.labelMensual = producto.labelMensual
  //   datosFPApp.colorDefault = producto.colorDefault

  //   // setFormaPago(datosFPApp.arrayDefault[datosFPApp.arrayDefault?.length - 1].label)
  //   setFormaPago(datosFPApp.arrayDefault[datosFPApp.arrayDefault?.length - 1].label)
  //   setCF(datosFPApp.colorDefault)
  // }, [props.dedicacion, datosFPApp])

  useEffect(() => {
    let dataFlujo = data.allComponentsJson.edges[0].node.plazos.filter(item => item.company === props.company)
    if (props.dedicacion) {
      const producto = dataFlujo.find(item => item.producto === props.dedicacion)
      datosFPApp.arraySemanas = producto.arraySemanas
      datosFPApp.arrayQuincenas = producto.arrayQuincenas
      datosFPApp.arrayMeses = producto.arrayMeses
      datosFPApp.arrayDefault = producto.arrayDefault[0] === "arraySemanas" && producto.arraySemanas || producto.arrayDefault[0] === "arrayQuincenas" && producto.arrayQuincenas || producto.arrayDefault[0] === "arrayMeses" && producto.arrayMeses
      datosFPApp.labelSemanal = producto.labelSemanal
      datosFPApp.labelQuincenal = producto.labelQuincenal
      datosFPApp.labelMensual = producto.labelMensual
      datosFPApp.colorDefault = producto.colorDefault
    }
    // if (formaPago !== '' && props.dinamicFile) {
    const { formaPago } = props.configuration
    setFormaPago(formaPago)
    // } else {
    //   setFormaPago(datosFPApp.arrayDefault[datosFPApp.arrayDefault?.length - 1].label)
    // }
    setCF(datosFPApp.colorDefault)
  }, [props.dedicacion, datosFPApp])


  return (
    <FormaPago
      cf={CF}
      formaPago={formaPago}
      configuration={datosFPApp}
      onClick={
        (event) => {
          event = JSON.parse(event)
          props.handleChange('plazo', { value: event.value })
          props.handleChange('terms', { value: Number(event.extra.periodicidad) })
          props.handleChange('period', { value: event.extra.periodo })
        }
      }
      id="simulador-forma-pago"
      disabled={!props.dedicacion || !props.monto}
    />
  );
}

export default FormaPagoAppOferta;